import React from "react";
import ReviewsComp from "../Components/Home/ReviewsComp";

export default function Reviews() {
  return (
    <div>
      <ReviewsComp from={"page"} />
    </div>
  );
}
