import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "../Common/Loader";
import ProductsCard from "../Common/ProductCard";

export default function Hijab() {
  const { isLoading, error, data } = useQuery({
    queryKey: ["products"],
    queryFn: () =>
      fetch("https://api.islamicposhak.com/api/product").then((res) =>
        res.json()
      ),
  });
  return (
    <>
      {data?.data.filter((product) => product.category == "Hijab")?.length >
      0 ? (
        <>
          <h4 className="text-3xl text-center pt-10">
            <span className="text-green-500">হিজাব কালেকশন</span>
          </h4>
          <p class="max-w-md mx-auto mt-4 text-gray-500 text-center">
            আপনার জন্য সকল ধরনের হিজাব বিদ্যমান রয়েছে.
          </p>

          {isLoading ? (
            <div>
              <Loader />
            </div>
          ) : error ? (
            <h1>{error}</h1>
          ) : null}
          <div className="grid grid-cols-2 gap-3  md:grid-cols-3 lg:grid-cols-6 px-5 lg:px-28 xl:px-36 mt-5">
            {data?.data
              .filter((product) => product.category === "Hijab")
              .map((product) => (
                <ProductsCard key={product._id} product={product} />
              ))}
          </div>
        </>
      ) : (
        <>
          <div className="h-screen">
            {" "}
            <span className="text-md text-red-500 font-bold  flex justify-center items-center">
              No Hijab found
            </span>
          </div>
        </>
      )}
    </>
  );
}
