import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "../Common/Loader";
import ProductsCard from "../Common/ProductCard";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { Link } from "react-router-dom";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function ProductsComp() {
  const { isLoading, error, data } = useQuery({
    queryKey: ["products"],
    queryFn: () =>
      fetch("https://api.islamicposhak.com/api/product").then((res) =>
        res.json()
      ),
  });
  return (
    <>
      <h4 className="text-3xl text-center pt-10">
        <span className="text-green-500">বোরকা কালেকশন</span>
      </h4>
      <p class="max-w-md mx-auto mt-4 text-gray-500 text-center">
        আপনার জন্য সকল ধরনের বোরকা বিদ্যমান রয়েছে.
      </p>

      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : error ? (
        <h1>{error}</h1>
      ) : null}

      <div className="block md:hidden p-2">
        <Swiper
          navigation={true}
          autoplay={{ delay: 5000, disableOnInteraction: true }}
          modules={[Navigation, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {data?.data
            .filter((product) => product.category === "Borka")

            .map((product) => (
              <SwiperSlide key={product._id}>
                <li>
                  <Link
                    to={`/productDetails/${product?._id}`}
                    className="group block overflow-hidden"
                  >
                    <img
                      src={product?.colors[0]?.image}
                      alt=""
                      className="max-h-[500px] w-full object-cover transition duration-500 group-hover:scale-105 "
                    />

                    <div className="relative bg-white pt-3">
                      <h3 className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4">
                        {product?.name}
                      </h3>

                      <p className="mt-2 flex justify-between">
                        <span className="sr-only"> Regular Price </span>

                        <span className="tracking-wider text-gray-900">
                          {" "}
                          {parseFloat(
                            product?.price * (1 - product?.discount / 100)
                          ).toFixed(0)}
                          ৳{" "}
                        </span>
                        {product?.discount > 0 && (
                          <del>
                            <span className="tracking-wider text-gray-900">
                              {" "}
                              {product?.price?.toFixed(0)} ৳{" "}
                            </span>
                          </del>
                        )}
                      </p>
                    </div>
                  </Link>
                </li>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>

      <div className="hidden md:grid grid-cols-2 gap-3  md:grid-cols-3 lg:grid-cols-6 px-5 lg:px-28 xl:px-36 mt-5">
        {data?.data
          .filter((product) => product.category === "Borka")
          ?.slice(0, 8)
          .map((product) => (
            <ProductsCard key={product._id} product={product} />
          ))}
      </div>
    </>
  );
}
